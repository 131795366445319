import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as TestSectionApi from '../apis/testSection';
import { TestSectionAction } from '../actions';
import { toast } from 'react-toastify';
import { History } from '../helpers/history';
import * as QueryString from 'query-string';
import { getLocalStorageWithExpiry, getUniqueDeviceId } from '../helpers/storage';
import moment from 'moment';
import TestSectionTypes from '../constants/testSectionTypes';
import setLastSeen from '../helpers/lastSeenHelper';
import ExamCategory from '../mobileAssets/enums/ExamCategory';
import ResponseStatus from '../mobileAssets/enums/ResponseStatus';
import { reverseAndExtractStatus } from '../containers/achievementTest/util';
import { GetTestSectionElementRequestType } from '../enums/GetTestSectionElementRequestType';

export function* watcherTestSection() {
	yield takeLatest(types.GET_TEST_SECTION_V4_REQUEST, workerGetTestSectionsV4);
	yield takeLatest(types.UPDATE_TEST_SECTION_ELEMENT_REQUEST, workerUpdateTestSectionElement);
	yield takeLatest(
		types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_REQUEST,
		workerUpdateTestSectionElementAndGetNextQuestion,
	);
	yield takeLatest(types.FINISH_SECTION_V2_REQUEST, workerFinishSectionV2);
	yield takeLatest(types.CREATE_TEST_SECTION_V6_REQUEST, workerCreateTestSectionV6);
	yield takeLatest(types.ADD_SOLVED_TEST_FROM_OUTSIDE_REQUEST, workerAddSolvedTestFromOutside);
	yield takeLatest(types.CREATE_TEST_SECTION_ELEMENT_V2_REQUEST, workerCreateTestSectionElementV2);
	yield takeLatest(types.GET_TEST_SECTION_ELEMENT_REQUEST, workerGetTestSectionElement);
	yield takeLatest(types.GET_FINISHED_TEST_SECTION_SUMMARY_REQUEST, workerGetFinishedTestSectionSummary);
	yield takeLatest(types.GET_TEST_SECTION_SUB_COURSE_SUMMARY, workerGetTestSectionSubCourseSummary);
	yield takeLatest(types.GET_TEST_SECTION_COURSE_SUMMARY, workerGetTestSectionCourseSummary);
	yield takeLatest(types.GET_TEST_SECTION_SUMMARY_REQUEST, workerGetTestSectionSummary);
	yield takeLatest(types.GET_TEST_SECTION_SUMMARY_REQUEST_V3, workerGetTestSectionSummaryV3);
	yield takeLatest(types.UPDATE_TEST_SECTION_V2_REQUEST, workerUpdateTestSectionV2);
	yield takeLatest(types.DELETE_TEST_SECTION_REQUEST, workerDeleteTestSection);
	yield takeLatest(types.GET_USER_SCORE_NET, workerGetUserScoreNet);
	yield takeLatest(types.GET_ALL_USERS_SCORE_NET, workerGetAllUsersCoreNet);
	yield takeLatest(types.GET_TEST_SECTION_ELEMENTS, workerGetTestSectionElements);
	yield takeLatest(types.GET_SUB_COURSE_SUCCESS_LEVEL, workerGetSubCourseSuccessLevel);
	yield takeLatest(types.GET_LAST_UNFINISHED_TEST, workerGetLastUnfinishedTest);
	yield takeLatest(types.CREATE_ACHIEVEMENT_ELEMENT, workerCreateAchievementElement);
	yield takeLatest(types.GET_QUESTION, workerGetQuestion);
	yield takeLatest(types.GET_TEST_SECTION_ELEMENT_BY_ID, workerGetTestSectionElementById);
	yield takeLatest(types.GET_LAST_THREE_ACHIEVEMENT_QUESTION, workerGetLastThreeAchievementQuestion);
}

export const getQuestion = store => store.Question;
export const getCreateTestSectionV6 = store => store.TestSection.createTestSectionV6.data;
export const getParam = () => QueryString.parse(window.location.search);

function getTestSectionsV4(data) {
	return TestSectionApi.getTestSectionsV4(data);
}
function* workerGetTestSectionsV4(action) {
	try {
		const response = yield call(getTestSectionsV4, action.data);
		yield put(TestSectionAction.getTestSectionsV4Success(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionsV4Failure());
	}
}

function updateTestSectionElement(data) {
	return TestSectionApi.updateTestSectionElement(data);
}
function* workerUpdateTestSectionElement(action) {
	try {
		const response = yield call(updateTestSectionElement, action.payLoad.data);
		yield put(TestSectionAction.updateTestSectionElementSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.updateTestSectionElementFailure());
	}
}

function updateTestSectionElementAndGetNextQuestion(data) {
	return TestSectionApi.updateTestSectionElement(data);
}
function* workerUpdateTestSectionElementAndGetNextQuestion(action) {
	try {
		const response = yield call(updateTestSectionElementAndGetNextQuestion, action.payLoad.data);
		yield put(TestSectionAction.updateTestSectionElementAndGetNextQuestionSuccess(response.data));
		const param = yield select(getParam);
		localStorage.setItem('last_' + action.payLoad.testSectionId + '_7', param.question);
		if (Number(param.question) < Number(action.payLoad.currentQuestion)) {
			const postData = {
				TestSectionId: action.payLoad.testSectionId,
				QuestionNumber: Number(param.question),
				ChannelCode: 'Web',
			};
			yield put(TestSectionAction.getTestSectionElement(postData));
		} else {
			const lastSeenQuestion = JSON.parse(sessionStorage.getItem('lastSeenQuestion'));
			const testSectionStatus = Number(localStorage.getItem('testSectionStatus'));
			const totalQuestion = Number(param.question); //JSON.parse(sessionStorage.getItem('totalQuestion'));
			let isOldQuestion = totalQuestion <= lastSeenQuestion;
			if (testSectionStatus === 2) {
				isOldQuestion = true;
			}
			if (isOldQuestion) {
				const postData = {
					TestSectionId: action.payLoad.testSectionId,
					QuestionNumber: Number(param.question),
					ChannelCode: 'Web',
				};
				yield put(TestSectionAction.getTestSectionElement(postData));
			} else {
				const createTestData = JSON.parse(sessionStorage.getItem('createTestData'));
				const postData = {
					UserId: getLocalStorageWithExpiry('userId'),
					TestSectionId: action.payLoad.testSectionId,
					ExamTypeId: Number(param.type),
					CourseId: createTestData.courseId,
					CourseTopicId: createTestData.courseTopicId,
					SubCourseTopicId: createTestData.subCourseTopicId,
					DeviceId: getUniqueDeviceId(),
					ChannelCode: 'Web',
				};
				yield put(TestSectionAction.createTestSectionElementV2(postData));
			}
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.updateTestSectionElementAndGetNextQuestionFailure());
	}
}

function finishSectionV2(data) {
	return TestSectionApi.finishSectionV2(data);
}
function* workerFinishSectionV2(action) {
	const { TestSectionId, ChannelCode, testSectionType } = action.data
	try {
		const response = yield call(finishSectionV2, { TestSectionId, ChannelCode });
		yield put(TestSectionAction.finishSectionV2Success(response.data));
		// yield put(AccountAction.setUserCoupon(response.data.EarnedCoupon));
		// yield put(AccountAction.setUserLottery(response.data.EarnedLottery));
		sessionStorage.setItem('finishedExam', response.data.ResponseData.TestSectionId);
		localStorage.setItem('testSectionStatus', 2);
		if(Number(testSectionType) === TestSectionTypes.Printable) {
			if(response.data.ResponseData.SubCourseTopicName =="Konu Tarama Testi"){
				History.push(`/ktt-test-sonucu/${action.data.TestSectionId}/0`);
			}else{
				History.push(`/test-sonucu/${action.data.TestSectionId}`);
			}
		} else {
			History.push(`/test-sonucu/${action.data.TestSectionId}`);
		}
	} catch (error) {
		if (error.status === 400) {
			if(Number(testSectionType) === 2) {
				History.push(`/ktt-test-sonucu/${action.data.TestSectionId}/0`);
			} else {
				History.push(`/test-sonucu/${action.data.TestSectionId}`);
			}
		} else {
			if (error && error.data && error.data.Error) {
				toast.error(error.data.Error.toString());
			}
		}
		yield put(TestSectionAction.finishSectionV2Failure());
	}
}

function createTestSectionV6(data) {
	return TestSectionApi.createTestSectionV6(data);
}
function* workerCreateTestSectionV6(action) {
	try {
		const { courseId, trialTitle, userExamCategory, fromTrialDetail , isShowAnswer = 0 , ...res } = action.data;
		const response = yield call(createTestSectionV6, res);
		yield put(TestSectionAction.createTestSectionV6Success(response.data));
		if (response.data && response.data.ResponseData && response.data.ResponseData.TestSectionId) {
			const courseId = response.data.ResponseData.CourseId;
			const courseTopicId = response.data.ResponseData.CourseTopicId;
			const subCourseTopicId = response.data.ResponseData.SubCourseTopicId;
			sessionStorage.setItem('createTestData', JSON.stringify({ courseId, courseTopicId, subCourseTopicId }));
			const type = response.data.ResponseData.AnswerOptionStatus;
			const testSectionId = response.data.ResponseData.TestSectionId;
			const solvedQuestion = response.data.ResponseData.SolvedQuestion;
			const totalQuestion = response.data.ResponseData.TotalQuestion;
            const TestSectionType = response.data.ResponseData.TestSectionType;
			sessionStorage.setItem('lastSeenQuestion', solvedQuestion);
			sessionStorage.setItem('totalQuestion', totalQuestion);
			localStorage.setItem('testSectionStatus', 1);
			setLastSeen(testSectionId,solvedQuestion);
			if (TestSectionType === TestSectionTypes.TrialExam) {
				localStorage.setItem('TrialExamId', action.data.TrialExamId);
				localStorage.setItem('TrialExamName', action.data.TrialExamName);
				localStorage.setItem('SessionType', action.data.SessionType);
				localStorage.setItem('SubTestName', action.data.SubTestName);
				if (action.redirectStatus === 1) {
					if (action.data.ShowQuestions) {
						if (action.data.userExamCategory === ExamCategory.YKS) {
							History.push(
								`/deneme-analizi/${action.data.TrialExamId}/${action.data.courseId}/${action.data.trialTitle}/${testSectionId}/0/${fromTrialDetail}`,
							);
						} else if(action.data.userExamCategory === ExamCategory.LGS) {
							History.push(`/test-sonucu/${testSectionId}?isTrailExam=true&Title=` + action.data.TrialExamName);
						} //todo: KPSS
					} else {
						History.push(
							`/denemeler/test/${testSectionId}/${isShowAnswer}/?type=${type}&question=${solvedQuestion}&TestSectionType=` +
								TestSectionType,
						);
					}
				}
			} else {
				if(action.redirectStatus === 1) {
					History.push(`/test/${testSectionId}?type=${type}&question=${solvedQuestion}`);
				}
			}
		} else {
			toast.warning(response.data.ResponseMessage);
			// History.push('/uyelik-bilgileri');
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.createTestSectionV6Failure());
	}
}

function addSolvedTest(data) {
	return TestSectionApi.createTestSectionV6(data);
}
function* workerAddSolvedTestFromOutside(action) {
	try {
		const response = yield call(addSolvedTest, action.data);
		yield put(TestSectionAction.addSolvedTestSuccess(response.data));
		const response2 = yield call(getTestSectionsV4, {});
		yield put(TestSectionAction.getTestSectionsV4Failure());
		yield put(TestSectionAction.getTestSectionsV4Success(response2.data.filter(i => i.TestSectionType !== 7)));
		// History.push('/');
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.addSolvedTestFailure());
	}
}

function createTestSectionElementV2(data) {
	return TestSectionApi.createTestSectionElementV2(data);
}
function* workerCreateTestSectionElementV2(action) {
	try {
		const response = yield call(createTestSectionElementV2, action.data);
		if (response.data.ResponseMessage) {
			toast.error(response.data.ResponseMessage.toString());
			setTimeout(() => {
				History.push('/yayinci-tercihlerim');
			}, 2000);
		} else {
			yield put(TestSectionAction.createTestSectionElementV2Success(response.data));
			const solvedQuestion = response.data.ResponseData.QuestionNumber;
			const totalQuestion = response.data.ResponseData.TotalQuestion;

			sessionStorage.setItem('lastSeenQuestion', solvedQuestion);
			sessionStorage.setItem('totalQuestion', totalQuestion);
			sessionStorage.setItem('latestRequest', 'create');
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.createTestSectionElementV2Failure());
	}
}

function getTestSectionElement(data) {
	return TestSectionApi.getTestSectionElement(data);
}
function* workerGetTestSectionElement(action) {
	try {
		const response = yield call(getTestSectionElement, action.data);
		yield put(TestSectionAction.getTestSectionElementSuccess(response.data));
		localStorage.setItem('selectedCourseName', response.data.CourseName);
		localStorage.setItem('selectedSubCourseTopicId', response.data.SubCourseTopicId);
		localStorage.setItem('selectedSubCourseTopicName', response.data.SubCourseTopicName);
		const param = yield select(getParam);
		if (
			response.data.UserAnswer === null &&
			Number(sessionStorage.getItem('lastSeenQuestion')) === Number(param.question)
		) {
			sessionStorage.setItem('latestRequest', 'create');
		} else {
			sessionStorage.setItem('latestRequest', 'get');
		}
		// const solvedQuestion = response.data.QuestionNumber;
		// const totalQuestion = response.data.TotalQuestion;
		// sessionStorage.setItem('lastSeenQuestion', solvedQuestion);
		// sessionStorage.setItem('totalQuestion', totalQuestion);
	} catch (error) {
		//console.log(error);
		if (error && error.status === 400) {
			History.goBack();
		}
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionElementFailure());
	}
}

function getFinishedTestSectionSummary(data) {
	return TestSectionApi.getFinishedTestSectionSummary(data);
}
function* workerGetFinishedTestSectionSummary(action) {
	try {
		const response = yield call(getFinishedTestSectionSummary, action.data);
		yield put(TestSectionAction.getFinishedTestSectionSummarySuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getFinishedTestSectionSummaryFailure());
	}
}

function getTestSectionCourseSummary(data) {
	return TestSectionApi.getTestSectionCourseSummary(data);
}
function* workerGetTestSectionCourseSummary(action) {
	try {
		const response = yield call(getTestSectionCourseSummary, action.data);
		yield put(TestSectionAction.getTestSectionCourseSummarySuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionCourseSummaryFailure());
	}
}

function getTestSectionSubCourseSummary(data) {
	return TestSectionApi.getTestSectionSubCourseSummary(data);
}
function* workerGetTestSectionSubCourseSummary(action) {
	try {
		const response = yield call(getTestSectionSubCourseSummary, action.data);
		yield put(TestSectionAction.getTestSectionSubCourseSummarySuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionSubCourseSummaryFailure());
	}
}

function getTestSectionSummary(data) {
	return TestSectionApi.getTestSectionSummary(data);
}
function* workerGetTestSectionSummary(action) {
	try {
		const response = yield call(getTestSectionSummary, action.data);
		yield put(TestSectionAction.getTestSectionSummarySuccess(response.data));
		// const lastItemIndex = response.data.TestSectionSummaryDetails.length - 1;
		// const testSectionId = action.data.TestSectionId;
		// const solvedQuestion = response.data.TestSectionSummaryDetails[lastItemIndex].QuestionNumber;
		// const totalQuestion = response.data.TestSectionSummaryDetails.length;
		// sessionStorage.setItem('lastSeenQuestion', solvedQuestion);
		// sessionStorage.setItem('totalQuestion', totalQuestion);
		// History.push(`/test/${testSectionId}?question=${solvedQuestion}`);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionSummaryFailure());
	}
}

function getTestSectionSummaryV3(data) {
	return TestSectionApi.getTestSectionSummaryV3(data);
}
function* workerGetTestSectionSummaryV3(action) {
	try {
		const response = yield call(getTestSectionSummaryV3, action.data);
		yield put(TestSectionAction.getTestSectionSummarySuccessV3(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionSummaryFailureV3());
	}
}

function updateTestSectionV2(data) {
	return TestSectionApi.updateTestSectionV2(data);
}
function* workerUpdateTestSectionV2(action) {
	try {
		const { TestSectionId, testSectionType, sectionType } = action.data
		const response = yield call(updateTestSectionV2, { TestSectionId });
		yield put(TestSectionAction.updateTestSectionV2Success(response.data));
		if (response.data && response.data.ResponseData) {
			const courseId = response.data.ResponseData.CourseId;
			const courseName = response.data.ResponseData.CourseName;
			const courseTopicId = response.data.ResponseData.CourseTopicId;
			const subCourseTopicId = response.data.ResponseData.SubCourseTopicId;
			sessionStorage.setItem('createTestData', JSON.stringify({ courseId, courseTopicId, subCourseTopicId }));
			const testSectionId = response.data.ResponseData.TestSectionId;
			const answerOptionStatus = response.data.ResponseData.AnswerOptionStatus;
			let solvedQuestion = response.data.ResponseData.SolvedQuestion;
			const totalQuestion = response.data.ResponseData.TotalQuestion;
			sessionStorage.setItem('lastSeenQuestion', solvedQuestion);
			sessionStorage.setItem('totalQuestion', totalQuestion);
			if (response.data.ResponseData.TestSectionType == 7) {
				solvedQuestion = 0
			}
			sessionStorage.setItem(testSectionId + 'totalDuration', response.data.ResponseData.TotalDuration);
			sessionStorage.setItem(testSectionId + 'testSectionType', response.data.ResponseData.TestSectionType);
			localStorage.setItem('testSectionId', testSectionId);
			localStorage.setItem('testSectionStatus', response.data.ResponseData.TestSectionStatus);
			if(sectionType === 2) {
				History.push(`/test/${testSectionId}?type=${answerOptionStatus}&question=${solvedQuestion}&testSectionType=${TestSectionTypes.Free}`);
			} else {
				if(Number(testSectionType) === TestSectionTypes.Free) {
					History.push(`/test/${testSectionId}?type=${answerOptionStatus}&question=${solvedQuestion}&testSectionType=${TestSectionTypes.Free}`); 
				} else {
					History.push(`/test/${testSectionId}?type=${answerOptionStatus}&question=${solvedQuestion}&testSectionType=${TestSectionTypes.Printable}&isKTT=${courseName == "KTT"}`);
				}
			}
			// yield put(TestSectionAction.getTestSectionElement(data, response.data.ResponseData.AnswerOptionStatus));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.updateTestSectionV2Failure());
	}
}

function deleteTestSection(data) {
	return TestSectionApi.deleteTestSection(data);
}
function* workerDeleteTestSection(action) {
	try {
		const response = yield call(deleteTestSection, action.data);
		yield put(TestSectionAction.deleteTestSectionSuccess(response.data));
		const now = moment().format('YYYY-MM-DD');
		const response2 = yield call(getTestSectionsV4, {});
		yield put(TestSectionAction.getTestSectionsV4Failure());
		yield put(TestSectionAction.getTestSectionsV4Success(response2.data.filter(i => i.TestSectionType !== 7)));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.deleteTestSectionFailure());
	}
}

function getUserScoreNet(data) {
	return TestSectionApi.getUserScoreNet(data);
}
function* workerGetUserScoreNet(action) {
	try {
		const response = yield call(getUserScoreNet, action.data);
		yield put(TestSectionAction.getUserScoreNetSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getUserScoreNetFailure());
	}
}

function getAllUsersScoreNet(data) {
	return TestSectionApi.getAllUsersScoreNet(data);
}
function* workerGetAllUsersCoreNet(action) {
	try {
		const response = yield call(getAllUsersScoreNet, action.data);
		yield put(TestSectionAction.getAllUsersScoreNetSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getAllUsersScoreNetFailure());
	}
}

function getTestSectionElements(data) {
	return TestSectionApi.getTestSectionElements(data);
}
function* workerGetTestSectionElements(action) {
	try {
		const response = yield call(getTestSectionElements, action.data);
		yield put(TestSectionAction.getTestSectionElementsSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionElementsFailure());
	}
}

function getSubCourseSuccessLevel(data) {
	return TestSectionApi.getSummary(data);
}
function* workerGetSubCourseSuccessLevel(action) {
	try {
		const response = yield call(getSubCourseSuccessLevel, action.data);
		yield put(TestSectionAction.getSubCourseSuccessLevelSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getSubCourseSuccessLevelFailure());
	}
}

function getLastUnfinishedTest(subCourseTopicUniqueId) {
	return TestSectionApi.getLastUnfinishedTest({ SubCourseTopicUniqueId: subCourseTopicUniqueId });
}
function* workerGetLastUnfinishedTest(action) {
	try {
		const response = yield call(getLastUnfinishedTest, action.data);
		if(response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(TestSectionAction.getLastUnFinishedTestActionSuccess(response.data.ResponseData));
			yield put(TestSectionAction.setShowUnfinishedTestPopup(true));
		} else {
			yield put(TestSectionAction.setShowTakeAnExamPopup(true));
			yield put(TestSectionAction.getLastUnFinishedTestActionSuccess(null));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getLastUnFinishedTestActionFailure());
	}
}

function createAchievementElement(data) {
	return TestSectionApi.createAchievementElement(data);
}

function* workerCreateAchievementElement(action) {
	try {
		const response = yield call(createAchievementElement, action.data);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(TestSectionAction.createAchievementElementSuccess(response.data.ResponseData));
			yield put(TestSectionAction.setCurrentAchievementTestSectionElementId(response.data.ResponseData.TestSectionElementId));
			sessionStorage.setItem('currentAchievementTestSectionElementId', response.data.ResponseData.TestSectionElementId.toString());
			const lastThreeAchievementQuestionResponse = yield call(getLastThreeAchievementQuestion, action.data);
			const lastElementStatuses = reverseAndExtractStatus(lastThreeAchievementQuestionResponse.data.ResponseData);
			yield put(TestSectionAction.getLastThreeAchievementQuestionSuccess(lastElementStatuses));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.createAchievementElementFailure());
	}
}

function getQuestionAchievement(data) {
	return TestSectionApi.getQuestion(data);
}

function* workerGetQuestion(action) {
	try {
		const response = yield call(getQuestionAchievement, action.data);
		if (response.data.ResponseStatus !== ResponseStatus.Success && action.data.ElementRequestType === GetTestSectionElementRequestType.Next) {
			const request = {
				AchievementId: action.data.AchievementId,
			};
			const createAchievementElementResponse = yield call(createAchievementElement, request);
			if(createAchievementElementResponse.data.ResponseStatus === ResponseStatus.Success) {
				yield put(TestSectionAction.createAchievementElementSuccess(createAchievementElementResponse.data.ResponseData));
				yield put(TestSectionAction.setCurrentAchievementTestSectionElementId(createAchievementElementResponse.data.ResponseData.TestSectionElementId));
				sessionStorage.setItem('currentAchievementTestSectionElementId', createAchievementElementResponse.data.ResponseData.TestSectionElementId.toString());
				const lastThreeAchievementQuestionResponse = yield call(getLastThreeAchievementQuestion, request);
				const lastElementStatuses = reverseAndExtractStatus(lastThreeAchievementQuestionResponse.data.ResponseData);
				yield put(TestSectionAction.getLastThreeAchievementQuestionSuccess(lastElementStatuses));
				return
			}
			if(createAchievementElementResponse.data.ResponseStatus !== ResponseStatus.Restriction) {
				toast.error(createAchievementElementResponse.data.ResponseMessage);
				History.push('/uyelik-bilgileri');
			}
			return;
		}
		yield put(TestSectionAction.getQuestionSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getQuestionFailure());
	}
}

function getTestSectionElementById(data) {
	return TestSectionApi.getTestSectionElementById(data);
}

function* workerGetTestSectionElementById(action) {
	try {
		const response = yield call(getTestSectionElementById, action.data);
		yield put(TestSectionAction.getTestSectionElementByIdSuccess(response.data.ResponseData));
		sessionStorage.setItem('achievementTestElementId', JSON.stringify(action.data.TestSectionElementId));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getTestSectionElementByIdFailure());
	}
}

function getLastThreeAchievementQuestion(data) {
	return TestSectionApi.getLastThreeAchievementQuestion(data);
}

function* workerGetLastThreeAchievementQuestion(action) {
	try {
		const response = yield call(getLastThreeAchievementQuestion, action.data);
		const lastElementStatuses = reverseAndExtractStatus(response.data.ResponseData);
		yield put(TestSectionAction.getLastThreeAchievementQuestionSuccess(lastElementStatuses));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TestSectionAction.getLastThreeAchievementQuestionFailure());
	}
}