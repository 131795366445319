import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as UserStatisticApi from '../apis/userStatistic';
import { UserStatisticAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherUserStatistic() {
	yield takeLatest(types.GET_STUDY_STATISTICS_REQUEST, workerGetStudyStatistics);
	yield takeLatest(types.GET_USER_DAILY, workerGetDailyStudyStatistics);
	yield takeLatest(types.GET_USER_SOLVED_REQUEST, workerGetUserSolved);
	yield takeLatest(types.GET_USER_SOLVED_SUMMARY_REQUEST, workerGetUserSolvedSummary);
	yield takeLatest(types.GET_TEST_SECTION_SUMMARY_ANALYTICS, workerGetTestSectionSummaryAnalytics);
	yield takeLatest(types.GET_ELEMENT_SUMMARY, workerGetElementSummary);
}

function getStudyStatistics(filter) {
	return UserStatisticApi.getStudyStatistics(filter);
}
function* workerGetStudyStatistics(action) {
	try {
		const response = yield call(getStudyStatistics, action.filter);
		yield put(UserStatisticAction.getStudyStatisticsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(UserStatisticAction.getStudyStatisticsFailure());
	}
}

function getDailyStatistics(filter) {
	return UserStatisticApi.getDailyStatistics(filter);
}
function* workerGetDailyStudyStatistics(action) {
	try {
		const response = yield call(getDailyStatistics, action.filter);
		yield put(UserStatisticAction.getUserDailySuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(UserStatisticAction.getStudyStatisticsFailure());
	}
}

function getUserSolved() {
	return UserStatisticApi.getUserSolved();
}
function* workerGetUserSolved() {
	try {
		const response = yield call(getUserSolved);
		yield put(UserStatisticAction.getUserSolvedSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(UserStatisticAction.getUserSolvedFailure());
	}
}

function getUserSolvedSummary(data) {
	return UserStatisticApi.getUserSolvedSummary(data);
}
function* workerGetUserSolvedSummary(action) {
	try {
		const response = yield call(getUserSolvedSummary, action.data);
		yield put(UserStatisticAction.getUserSolvedSummarySuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(UserStatisticAction.getUserSolvedSummaryFailure());
	}
}

function getTestSectionSummaryAnalytics(data) {
	return UserStatisticApi.gettestsectionsummaryanalytics(data);
}
function* workerGetTestSectionSummaryAnalytics(action) {
	try {
		const response = yield call(getTestSectionSummaryAnalytics, action.data);
		yield put(UserStatisticAction.getTestSectionSummaryAnalyticsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(UserStatisticAction.getTestSectionSummaryAnalyticsFailure());
	}
}

function getElementSummary(data) {
	return UserStatisticApi.getElementSummaryV2(data);
}

function* workerGetElementSummary(action) {
	try {
		const response = yield call(getElementSummary, action.data);
		yield put(UserStatisticAction.getElementSummarySuccess(response.data.ResponseData));
		yield put(UserStatisticAction.setShowSummaryQuestions(true));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error('Beklenmedik bir hata oluştu');
		}
		yield put(UserStatisticAction.getElementSummaryFailure());
	}
}
